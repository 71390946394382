import { graphql } from '../../gql';

export const TOGGLE_BIND_STATUS = graphql(`
  mutation toggleBindStatus(
    $token: String!
    $uniqueId: String!
    $bound: Boolean!
    $unboundReason: String
  ) {
    toggleBindStatus(
      token: $token
      uniqueId: $uniqueId
      bound: $bound
      unboundReason: $unboundReason
    ) {
      quote {
        id
        isBound
        unboundReason
      }
    }
  }
`);
