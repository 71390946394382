import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { TOGGLE_BIND_STATUS } from '../../../../queries';
// import Button from '@mui/material/Button';
import { Quote } from '../../../../Typescript/backend/classes';
import Label from '../../components/Label';
import { useUsingVagoSettings } from '../../../../hooks';
import { useAuth } from '@calefy-inc/authentication';
// import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
// import Bugsnag from '@bugsnag/browser';
// import { errorify } from '@calefy-inc/utility';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';

interface QuoteBoundStatusProps {
  quote: Quote;
}
export const QuoteBoundStatus = ({ quote }: QuoteBoundStatusProps) => {
  const { token } = useAuth();
  const onVago = useUsingVagoSettings();
  const [toggleBoundStatus, { loading, error, data }] = useMutation(
    TOGGLE_BIND_STATUS,
    {
      refetchQueries: ['Quote'],
      update: (cache, { data }) => {
        if (!data?.toggleBindStatus?.quote) {
          return;
        }
        const id = cache.identify(data?.toggleBindStatus?.quote);
        cache.modify({
          id,
          fields: {
            isBound: () => data?.toggleBindStatus?.quote?.isBound,
            unboundReason: () => data?.toggleBindStatus?.quote?.unboundReason,
          },
        });
      },
    },
  );
  const [isBound, setIsBound] = useState<Quote['isBound']>(quote.isBound);
  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);
  const [showBindButton, setShowBindButton] = useState<boolean>(!quote.isBound);
  const { enqueueSnackbar } = useSnackbar();

  // update the bound status
  useEffect(() => {
    if (data?.toggleBindStatus?.quote) {
      setIsBound(data.toggleBindStatus.quote.isBound);
    }
  }, [data]);

  // update the error message
  useEffect(() => {
    if (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }, [error]);

  // update whether or not we should show the button to bind / unbind the quote
  useEffect(() => {
    if (quote) {
      setShowBindButton(!!token);
    }
  }, [token, quote]);

  // whenever we get a response hide the button until there's a chance to set the correct state
  useEffect(() => {
    if (data) {
      setShowBindButton(false);
    }
  }, [data]);

  if (!onVago) {
    return null;
  }
  return (
    <BoundContainer>
      <BoundStatusContainer>
        <Label color={isBound ? 'success' : 'warning'}>
          {isBound ? 'BOUND' : 'NOT BOUND'}
        </Label>
        {showBindButton ? (
          <LoadingButton
            loading={loading}
            disabled={!token}
            onClick={() => setConfirmationOpen(true)}
          >
            {isBound ? 'Unbind' : 'Bind'}
          </LoadingButton>
        ) : null}
        <MarkQuoteBoundConfirmationDialog
          quote={quote}
          open={confirmationOpen}
          onCancel={() => setConfirmationOpen(false)}
          onConfirm={(unboundReason: string) => {
            toggleBoundStatus({
              variables: {
                token,
                uniqueId: quote.uniqueId,
                bound: !quote.isBound,
                unboundReason,
              },
            });
            setConfirmationOpen(false);
          }}
        />
      </BoundStatusContainer>
      {quote.unboundReason ? (
        <Typography variant='subtitle2'>
          Unbound reason: {quote.unboundReason}
        </Typography>
      ) : null}
    </BoundContainer>
  );
};

const BoundStatusContainer = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
  };
});

const BoundContainer = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    justifyContent: 'center',
    gap: theme.spacing(1),
  };
});

interface MarkQuoteBoundConfirmationDialogProps {
  quote: Quote;
  open: boolean;
  onConfirm: (unboundReason: string) => void;
  onCancel: () => void;
}
const MarkQuoteBoundConfirmationDialog = ({
  quote,
  open,
  onConfirm,
  onCancel,
}: MarkQuoteBoundConfirmationDialogProps) => {
  const [typedValue, setTypedValue] = useState<string>('');
  const [unboundReason, setUnboundReason] = useState<string>('');
  const [ok, setOk] = useState<boolean>(false);

  useEffect(() => {
    setOk(
      // we only need the reason if we're unbinding a quote
      (quote.isBound && !!unboundReason) || !quote.isBound,
    );
  }, [typedValue, quote, unboundReason]);

  return (
    <Dialog open={open}>
      <DialogTitle>Confirmation</DialogTitle>
      <DialogContent>
        {quote.isBound ? (
          <Typography>
            Are you sure? This will mark the quote as being unbound, allowing
            further edits.
          </Typography>
        ) : (
          <Typography>
            Are you sure? Once the quote has been marked as bound no further
            edits will be possible.{' '}
          </Typography>
        )}

        {quote.isBound ? (
          <TextField
            value={unboundReason}
            onChange={(e) => setUnboundReason(e.target.value)}
            label='Reason for Unbinding'
            required={true}
            fullWidth
            sx={{ mt: 1 }}
          />
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setTypedValue('');
            onCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!ok}
          onClick={() => {
            setTypedValue('');
            onConfirm(unboundReason);
          }}
        >
          Mark Quote as {quote.isBound ? 'Unbound' : 'Bound'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
